<template>
    <div class="my-qr">
        <div class="content">
            <div class="avatar">
                <van-image class="my-image" fit="cover" :src="getImg(userInfo.picHeadUrl)" alt="">
                    <template v-slot:error><van-image class="my-image" fit="cover" :src="avatar" alt=""/></template>
                </van-image>
            </div>
            <div class="name">
                用户名: {{userInfo.memberName || '***'}}
            </div>
            <van-image class="my-image" fit="cover" :src="qrUrl" alt="" />
            <!-- <div id="qrcode"></div> -->
        </div>
        <van-image class="my-hot" fit="cover" :src="hot" alt="" />
    </div>
</template>

<script>
// import QRCode from "qrcodejs2";
export default {
    data() {
        return {
            avatar: require('@/assets/img/avatar.jpeg'),
            hot: require('@/assets/img/hot.png'),
            userInfo: {},
            qrUrl : ``
        }
    },
    methods: {
        // qrcode() {
        //     this.$nextTick(()=>{  
        //     let returnUrl = window.encodeURIComponent(`http://horse-mobile.haplive.cn/#/horsefarm`)
        //     let token = ''
        //     if(this.$help.token.get()){
        //         token = window.encodeURIComponent(`${this.$help.token.get().split(' ')[1]}`)
        //     }
        //     this.qrUrl = `http://horse-mobile.haplive.cn/horse/member/base/member/member-invitation?returnUrl=${returnUrl}&token=${token}`
        //         new QRCode("qrcode", {
        //             width: 200, // 二维码宽度，单位像素
        //             height: 200, // 二维码高度，单位像素
        //             //http://192.168.3.253:8080/mobile/#/add/question/:id
        //             text: this.qrUrl // 生成二维码的链接
        //         });
        //         //console.log(window.location)
        //     }) 
            
        // },
        getMemberUser() { 
            this.$help.loading()
            this.$store.dispatch('getMemberUser').then(() => {
                this.userInfo = this.$store.state.memberUser
            })
            .finally(this.$help.loaded)
        },
    },
    mounted() {
        //this.qrcode()
        let returnUrl = window.encodeURIComponent(`https://wechat.hongqihorse.com/#/horsefarm`)
        let token = ''
        if(this.$help.token.get()){
            token = window.encodeURIComponent(`${this.$help.token.get().split(' ')[1]}`)
        }
        this.qrUrl = `https://wechat.hongqihorse.com/horse/member/base/member/member-invitation?returnUrl=${returnUrl}&token=${token}`
        this.getMemberUser()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .my-qr{
        padding: 30px;
        margin-top: 50px;
        .content{
            padding: 30px;
            box-shadow: 0 0 9px 6px $boxShadow;
            border-radius: 9px;
            position: relative;
            .avatar{
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 50%;
                .van-image{
                    height: 80px;
                    width: 80px;
                    box-shadow: 0 0 9px 6px $boxShadow;
                    border-radius: 50%;
                    img{
                        border-radius: 50%;
                    }
                }
            }
            .img{
                height: 150px;
                width: 150px;
            }
            .name{
                line-height: 80px;
                text-align: center;
            }  
        }
        .my-hot{
            margin-top: 40px;
        }
    }

</style>